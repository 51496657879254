import React from "react";
import Layout from "../components/layout";
import "../styles/pages/policy.scss";
export default function CancellationPolicy() {
  return (
    <Layout title="Cancellation Policy">
      <h1 className="page-title">Cancellation Policy</h1>
      <article>
        <section className="section">
          <h2>1. Cancellation Requests:</h2>
          <p>
            All cancellation requests must be submitted in writing to
            Nookampalayam Link Road Residents Welfare Association. Residents may
            submit cancellation requests via email or in person at the
            association's office.
          </p>
        </section>

        <section className="section">
          <h2>2. Refund Policy:</h2>
          <p>
            Refunds for cancelled memberships will be processed within 3
            business days from the date of cancellation request.
          </p>
          <ul>
            <li>Only the annual memebership fee is refundable.</li>
            <li>
              Refund amount is calculated based on the no. of whole months left
              in the membership tenure.
            </li>
            <li>Refund amount of Rs.50 is paid for one whole month.</li>
          </ul>
        </section>

        <section className="section">
          <h2>3. Non-Refundable Services:</h2>
          <p>Registeration fees is non-refundable.</p>
        </section>

        <section className="section">
          <h2>4. Contact Us:</h2>
          <p>
            If you have any questions about this Cancellation Policy or need
            assistance with a cancellation request, please contact us at{" "}
            <a href="mailto:nlrrwa2018@gmail.com"> nlrrwa2018@gmail.com</a>.
          </p>
        </section>
        <p className="last-updated">
          This Cancellation Policy was last updated on 12-Mar-2024.
        </p>
      </article>
    </Layout>
  );
}
